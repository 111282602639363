import React from 'react';


const NavigationDots = ({ active }) => {
    return (
        <div className='app__navigation'>
            {['home', 'about', 'who we are', 'tools', 'events', 'media', 'services', 'contact',].map((item, index) => (
                <a
                    href={`#${item}`}
                    key={item + index}
                    className='app__navigation-dot'
                    style={active === item ? { backgroundColor: '#313BC' } : {}}
                >{ }</a>

            ))}
        </div>
    )
}

export default NavigationDots