import React, { useState, useEffect } from 'react';
import { AiFillPlusCircle, AiOutlineLink } from 'react-icons/ai';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Media.scss';
import { HiXCircle } from 'react-icons/hi';
import { isMobile } from 'react-device-detect';


const Media = () => {

    const [activeFilter, setActiveFilter] = useState('All');
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
    const [medias, setMedias] = useState([]);
    const [filterMedia, setFilterMedia] = useState([]);

    const [popUpContent, setPopUpContent] = useState([]);
    const [popUpToggle, setPopUpToggle] = useState(false);

    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        const query = '*[_type == "media"]';

        client.fetch(query).then((data) => {

            setMedias(data);
            setFilterMedia(data.filter((item) => item.tags.includes('All')));



        });
    }, []);

    const handleWorkFilter = (item) => {
        setActiveFilter(item);
        setAnimateCard([{ y: 100, opacity: 0 }]);

        setTimeout(() => {
            setAnimateCard([{ y: 0, opacity: 1 }]);

            if (item === 'All') {
                setFilterMedia(medias);
            } else {
                setFilterMedia(medias.filter((media) => media.tags.includes(item)));
            }
        }, 500);
    };

    const handleModal = (media) => {

        setPopUpContent([
            media
        ]);

        setPopUpToggle(true);

        document.body.style.overflow = "hidden";

    };

    const closeModal = () => {

        setPopUpToggle(false);
        setPopUpContent([]);
        document.body.style.overflow = "auto";
    }

    return (
        <>
            <br />
            <div className='style-container'>
                <h2 className='head-text'>Articles, Podcasts & <span>More </span></h2>
                <div className='app__search-container app__flex'>
                    <input className="app__searchbar" placeholder='🔍 Search Media' onChange={event => setSearchString(event.target.value)} />

                </div>

                <div className='app__work-filter'>
                    {['New', 'Articles', 'Podcasts', 'Videos', 'Blog Posts', 'All'].map((item, index) => (
                        <div
                            key={index}
                            onClick={() => handleWorkFilter(item)}
                            className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
                        >
                            {item}
                        </div>
                    ))}
                </div>

                <motion.div
                    animate={animateCard}
                    transition={{ duration: 0.5, delayChildren: 0.5 }}
                    className='app__work-portfolio'
                >

                    {filterMedia.filter(media => {
                        if (searchString === '') {
                            return media;
                        } else if (media.title.toLowerCase().includes(searchString.toLowerCase())) {
                            return media;
                        }

                    }).map((media, index) => (

                        <div className='app__work-item app__flex' key={index}>
                            <div className='app__work-img app__flex'>
                                <a href={media.pdfLink} target="_blank" rel="noreferrer">
                                    <img src={urlFor(media.imgUrl)} alt={media.title} />
                                </a>
                                {!isMobile && <motion.div
                                    whileHover={{ opacity: [0, 1] }}
                                    transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                                    className='app__work-hover app__flex'
                                >
                                    <a href={media.pdfLink} target="_blank" rel="noreferrer">
                                        <motion.div
                                            whileInView={{ scale: [0, 1] }}
                                            whileHover={{ scale: [1, 0.9] }}
                                            transition={{ duration: 0.25 }}
                                            className='app__flex'

                                        >
                                            <AiOutlineLink />
                                        </motion.div>
                                    </a>

                                    <motion.div
                                        whileInView={{ scale: [0, 1] }}
                                        whileHover={{ scale: [1, 0.9] }}
                                        transition={{ duration: 0.25 }}
                                        className='app__flex'


                                    >
                                        {!popUpToggle && <AiFillPlusCircle
                                            onClick={() => handleModal(media)}
                                        />}




                                    </motion.div>

                                </motion.div>


                                }</div>

                            <div className='app__work-content app__flex'>
                                <h4 className='bold-text'>{media.title}</h4>
                                <p className='p-text' style={{ marginTop: 10 }}> {`${media.content.substring(0, 106)}...`}</p>

                                <div className='app__work-tag app__flex'>
                                    <p className='p-text'>{media.tags[0]}</p>
                                </div>
                            </div>

                        </div>

                    )
                    )
                    }

                </motion.div>
            </div>






            {popUpToggle && (
                <div className='popup_container' onClick={closeModal}
                >
                    <div className='popup_body' onClick={(e) => e.stopPropagation()}>
                        <div className="popup_header" onClick={closeModal}>
                            <HiXCircle size={40} />
                        </div>

                        <div className='popup_content'>

                            {popUpContent.map((pop) => {
                                return (
                                    <div className="popup_card">

                                        <div className='image_container app__flex'>
                                            <a href={`${pop.pdfLink}`} target="_blank" rel="noreferrer">
                                                <img src={urlFor(pop.imgUrl)} alt={pop.title} />
                                            </a>
                                        </div>
                                        <h2 className="head-text">{`About ${pop.title}:`}</h2>





                                        <ReactMarkdown className="p-text">{pop.contentF}</ReactMarkdown>




                                    </div>

                                )

                            })}
                        </div>
                    </div>



                </div>)}


        </>

    )
}


export default AppWrap(
    MotionWrap(Media, 'app__works'),
    'media',
    'app__primarybg'
);