import logo from '../assets/aluptrans.svg';
import logolong from '../assets/logolong.jpg';
import logosvg from '../assets/logo.svg';
import about1 from '../assets/about01.png';
import about2 from '../assets/about02.png';
import about3 from '../assets/about03.png';
import about4 from '../assets/about04.png';
import bgIMG from '../assets/bgIMG.png';
import bgWhite from '../assets/bgWhite.png';
import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import feelz from '../assets/feelz.png'
import circle from '../assets/circle.svg';
import fun from '../assets/fun.jpg';
import pow from '../assets/pow.jpg';
import stars from '../assets/stars.jpg';
import calendar from '../assets/calendar.png';
import pdf from '../assets/pdf.png';
import manual from '../assets/manual.jpg';

const images =  { 

    logo, 
    logolong,
    logosvg,
    email, 
    feelz,
    about1, 
    about2, 
    about3, 
    about4, 
    bgIMG, 
    bgWhite, 
    mobile, 
    circle,
    fun,
    pow,
    stars,
    calendar,
    pdf,
    manual,
};

export default images;