import React from 'react';
import { BsYoutube, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';


const SocialMedia = () => {
    return (
        <div className='app__social'>
            <div>
                <a href='https://www.facebook.com/AutismLevelUP/' target='_blank' rel='noreferrer'>
                    <FaFacebookF />
                </a>

            </div>
            <div>
                <a href='https://www.instagram.com/AutismLevelUP/' target='_blank' rel='noreferrer'>
                    <BsInstagram />
                </a>
            </div>
            <div>
                <a href='https://www.youtube.com/channel/UCLHr8gQ9ZsIHccgr5UtDRTw' target='_blank' rel='noreferrer'>
                    <BsYoutube />
                </a>
            </div>
        </div>
    )
}

export default SocialMedia