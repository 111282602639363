import React from 'react';

import { About, Header, Footer, Tools, Events, Testimonial, Levels } from './container';
import { Navbar } from './components';
import './App.scss';
import Media from './container/Media/Media';

const App = () => {
  return (
   <div className='app'>
    <Navbar />
    <Header />
   
    <Levels />
    <Testimonial />
    
    <Tools />
   
    <Events />
    <Media />
    <About />
  
    <Footer />
   </div>
  );
}

export default App;