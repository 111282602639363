import { useState, useEffect, React } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './Levels.scss';
import { urlFor, client } from '../../client';
import { images } from '../../constants';


const Levels = () => {
    const [levels, setLevels] = useState([]);

    useEffect(() => {
        const query = '*[_type == "levels"]';

        client.fetch(query).then((data) => {
            setLevels(data);
        });
    }, []);
    return (
        <>
            <br />
            <h2 className='head-text'>About Our <span>Level UP! </span><br />Levels <span>and Us</span></h2>
            <p className="p-text" style={{ marginTop: 10, fontSize: 18 }}>Autism Awareness? Autism Appreciation? Autism Acceptance?  None of these are a particularly high bar, but it is true that some people do, indeed, need real awareness while others need a better understanding of how to truly accept and appreciate.  Still, others need goals beyond these. Hence, Autism Level UP! Wherever you are, take the next step! We aim to meet people wherever they are in their journey and help them continue forward. From real awareness all the way through empowerment and advocacy. Come level UP! with us. You're welcome here no matter where you are starting. A great first step is to read about the levels below and decide honestly where you are.</p>
            <div className='app__profiles'>
                {levels.sort((a, b) => (a.position) - (b.position)).map((level, index) => (
                    <motion.div
                        whileInView={{ opacity: 1 }}
                        whileHover={{ scale: 1.1 }}
                        transition={{ duration: 0.5, type: 'tween' }}
                        className="app__profile-item"
                        key={level.title + index}
                    >
                        <img src={urlFor(level.imgUrl)} alt={level.title} />
                        <h2 className="bold-text" style={{ marginTop: 20 }}>{level.title}</h2>
                        <p className="p-text" style={{ marginTop: 10, fontSize: 16 }}>{level.description}</p>
                    </motion.div>
                ))}

            </div>
            <h2 className='head-text'>Get the Guide <span>to Leveling UP! </span><br />in 10 <span>Languages</span></h2>
            <div className='image-container'>
           
                <a href='https://drive.google.com/drive/folders/1x-blxQ-rd70nzyz5qxWkGflB8q5WubW7?usp=share_link' target="_blank" rel="noreferrer">
                    <img style={{ width: '150px', height: '150px' }} src={images.pdf} alt="find all translations of the level up guide here" />
                </a>
            </div>
        </>
    );
}

export default AppWrap(
    MotionWrap(Levels, 'app__about'),
    'about',
    'app__whitebg'
);