import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: 'p6bm7moz',
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: 'skTszy4KLJaUDAYl5RZnIMrBeWh47IXH3b1JG2Rielt06HtpJeAVxqrQDIQJ0sMC2MIWnuM914yMkzTCYpJkPfPz6X64J2jMFC0WXrZYjNJtlsKHmXj56Wt48AO6Il3d6HYAqTsAjT5bbjwm5eLw6m6XGLG43d7qJ0bYRBmZMxtJCMaPPm2p',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);